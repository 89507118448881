import React, {useState} from "react";
import {useQuery} from "react-query";
import {Link, NavLink} from "react-router-dom";
import {loginByPassword} from "../api/dashBoard";
import {useNavigate} from "react-router-dom";

interface LoginProps {
  setIsLoggin: any;
}

const Login: React.FC<LoginProps> = (loginProps: LoginProps) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setNewPassword(oldPassword);
  };

  // Login api
  const login = useQuery(
    ["login", [`${newPassword}`]],
    async () => await loginByPassword(newPassword),
    {
      onSuccess: async (res: any) => {
        if (res.status === 200) {
          console.log("login");
          loginProps.setIsLoggin(true);
        }
      },
      onError: (err: any) => console.log(err),
      enabled: !!newPassword, // disable this query from automatically running
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={oldPassword}
              onChange={(event) => setOldPassword(event.target.value)}
            />
          </div>
          <br />
          <button type="submit">Log In</button>
        </div>
      </form>
    </div>
  );
};

export default Login;
