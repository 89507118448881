import React, {PureComponent, useState} from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import moment from "moment";

const Chart = (props: any) => {
  const {weekCoachCounts, weekMemberCounts} = props.mainData;
  const date = props.date;
  const names = [];
  const data = [];

  for (let i = 6; i > -1; i--) {
    names.push(moment(date).subtract(i, "d").format("MM.DD"));
  }

  if (!!weekCoachCounts && !!weekMemberCounts) {
    for (let i = 0; i < 7; i++) {
      data.push({
        강사수: weekCoachCounts[i],
        회원수: weekMemberCounts[i],
        name: names[i],
      });
    }
  }

  return (
    <LineChart
      width={800}
      height={350}
      data={data}
      margin={{
        top: 30,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line
        type="monotone"
        dataKey="회원수"
        stroke="#82ca9d"
        activeDot={{r: 8}}
      />
      <Line
        type="monotone"
        dataKey="강사수"
        stroke="#8884d8"
        activeDot={{r: 8}}
      />
    </LineChart>
  );
};

export default Chart;
