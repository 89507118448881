import "./App.css";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";

import DashBoard from "./page/DashBoard";
import CoachList from "./page/CoachList";
import CoachScheduleList from "./page/CoachScheduleList";
import CoachUserFlowData from "./page/CoachUserFlowData";
import CoachUserFlowDataV3 from "./page/CoachUserFlowDataV3";
import RappoManagerData from "./page/RappoManagerData";
import RappoManagerBillingData from "./page/RappoManagerBillingData";

import Coach from "./page/Coach";
import Login from "./page/Login";
import SideBar from "./components/SideBar";
import axios from "axios";
import {useState} from "react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000,
      cacheTime: 2000,
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
    },
  },
});

// /* 가동 서버  */
axios.defaults.baseURL = "https://app.rappo.io";
// axios.defaults.baseURL = "http://localhost:3000/v1/";

function App() {
  const [isLoggin, setIsLoggin] = useState(false);

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <BrowserRouter>
          <SideBar />
          <div className="main-content">
            {isLoggin ? (
              <Routes>
                <Route path="/dashBoard" element={<DashBoard />} />
                <Route path="/coachList" element={<CoachList />} />
                <Route
                  path="/coachScheduleList"
                  element={<CoachScheduleList />}
                />
                <Route
                  path="/coachUserFlowData"
                  element={<CoachUserFlowData />}
                />
                <Route
                  path="/coachUserFlowDataV3"
                  element={<CoachUserFlowDataV3 />}
                />
                <Route
                  path="/rappoManagerData"
                  element={<RappoManagerData />}
                />
                <Route
                  path="/rappoManagerBillingData"
                  element={<RappoManagerBillingData />}
                />
                <Route path="/coach/:id" element={<Coach />} />
              </Routes>
            ) : (
              <Routes>
                <Route
                  path="/*"
                  element={<Login setIsLoggin={setIsLoggin} />}
                />
              </Routes>
            )}
          </div>
        </BrowserRouter>
      </div>
    </QueryClientProvider>
  );
}

export default App;
