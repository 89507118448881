import React, {useCallback, useState} from "react";
import {Calendar} from "react-date-range";
import ko from "date-fns/locale/ko"; // 날짜 포맷 라이브러리 (한국어 기능을 임포트)
import moment from "moment";

const DashBoardHeader = (props: any) => {
  const {showCalendar, setShowCalendar, date, onChangeDate} = props;

  return (
    <div className="main-header" style={{width: "100%"}}>
      <div className="input_box w33" style={{marginTop: 30, marginLeft: 20}}>
        <label>
          시작일
          <input
            type="text"
            readOnly={true}
            className="input block calendar"
            style={{marginLeft: 50, width: 200}}
            onClick={() => {
              setShowCalendar(!showCalendar);
            }}
            placeholder={moment(date).format("YYYY.MM.DD")}
          />
        </label>
      </div>
      <div className="customDateRangePicker">
        {showCalendar && (
          <Calendar
            locale={ko}
            months={1}
            date={date}
            onChange={onChangeDate}
            dateDisplayFormat={"YYYY.MM.DD"}
          />
        )}
      </div>
    </div>
  );
};

export default DashBoardHeader;
