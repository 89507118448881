import React from "react";
import { Link, useParams } from "react-router-dom";

const Coach = () => {
  const { id } = useParams();

  return (
    <>
      <h1> 코치</h1>

      <h3>{id}번 코치 페이지 입니다.</h3>
      <Link to="/">
        <li>메인으로 가기</li>
      </Link>
    </>
  );
};

export default Coach;
