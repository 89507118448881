import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import "../assets/css/CoachList.css";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {AgGridReact} from "ag-grid-react";

import moment from "moment";
import _DateRange from "../lib/_DateRange";
import {useQueries, useQuery, useQueryClient} from "react-query";
import {getCoachListByDate} from "../api/dashBoard";
import _ from "lodash";
import CustomPinnedRowRenderer from "../components/DashBoard/customPinnedRowRenderer";
import {Calendar} from "react-date-range";
import ko from "date-fns/locale/ko";

const CoachList = () => {
  const [selectDate, setSelectDate] = useState({
    date: [
      {
        startDate: moment().startOf("day").toDate(),
        endDate: moment().startOf("day").toDate(),
        key: "selection",
      },
    ],
  });

  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [rowData, setRowData] = useState([
    {
      index: 0,
      coachVisitDays: 0,
      coachName: "검색 클릭!",
      coachCenterName: "",
      coachBillingStatus: "",
      coachPlanAmount: 0,
      coachPhoneNumber: "",
      coachJoinDate: "",
      coachLastAccessDate: "",
      coachLastScheduleDate: "",
      connectMembers: 0,
      visitMembers: 0,
      dietMembers: 0,
      scheduleMembers: 0,
      totalSchedulesCount: 0,
      totalRequestsCount: 0,
      registerRequestCount: 0,
      changeRequestCount: 0,
      sessionMemberCount: 0,
      totalMealsCount: 0,
      accessFrequency: 0,
      videoViewCount: 0,
      bookmarkFolderCount: 0,
      bookmarkVideoCount: 0,
    },
  ]);

  const [showDate, setShowDate] = useState(false);
  const [showJoinDateCalendar, setShowJoinDateCalendar] =
    useState<boolean>(false);
  const [joinDate, setJoinDate] = useState<Date>(
    moment("2022-08-27").startOf("d").toDate()
  );

  const [columnDefs] = useState([
    {
      headerName: "색인",
      field: "index",
      width: 100,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          // rows that are not pinned don't use any cell renderer
          return undefined;
        }
      },
    },
    {
      headerName: "이름",
      field: "coachName",
      width: 100,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "센터명",
      field: "coachCenterName",
      width: 150,
      resizable: true,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "구독 상태",
      field: "coachBillingStatus",
      width: 250,
      resizable: true,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "개인구독 금액",
      field: "coachPlanAmount",
      width: 100,
      resizable: true,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "휴대폰 번호",
      field: "coachPhoneNumber",
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "강사 가입일",
      field: "coachJoinDate",
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "최종 접속일",
      field: "coachLastAccessDate",
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "최종 스케쥴 생성일",
      field: "coachLastScheduleDate",
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "접속일",
      field: "coachVisitDays",
      width: 100,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "접속빈도",
      field: "accessFrequency",
      width: 100,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "연결된 회원",
      field: "connectMembers",
      width: 120,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "접속한 회원",
      field: "visitMembers",
      width: 120,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "스케쥴한 회원",
      field: "scheduleMembers",
      width: 120,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "스케쥴 수",
      field: "totalSchedulesCount",
      width: 100,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "요청 수",
      field: "totalRequestsCount",
      width: 100,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "신청요청 수",
      field: "registerRequestCount",
      width: 100,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "변경요청 수",
      field: "changeRequestCount",
      width: 100,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "회차 등록 수",
      field: "sessionMemberCount",
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "식단한 회원",
      field: "dietMembers",
      width: 120,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "식단",
      width: 100,
      field: "totalMealsCount",
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "영상 조회수",
      width: 100,
      field: "videoViewCount",
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "북마크 폴더(현재)",
      width: 150,
      field: "bookmarkFolderCount",
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "북마크 비디오(현재)",
      width: 150,
      field: "bookmarkVideoCount",
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
  ]);

  //grid를 특정하기 위한 Hook
  const gridRef = useRef<AgGridReact<any>>(null);

  // 스케줄 조회 api
  const getCoachList = useQuery(
    ["getCoachListByDate", [`${startDate}`, `${endDate}`, `${joinDate}`]],
    async () =>
      await getCoachListByDate(
        moment(selectDate.date[0].startDate).toISOString(),
        moment(selectDate.date[0].endDate).add(1, "day").toISOString(),
        moment(joinDate).toISOString()
      ),
    {
      onSuccess: async (res) => {
        const result = _.cloneDeep(res.data);

        for (let i = 0; i < res.data.length; i++) {
          result[i].index = i + 1;
        }

        setRowData(result);
      },
      onError: (err) => console.log(err),
      enabled: !!startDate, // disable this query from automatically running
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );

  const handleShowDatePicker = () => {
    setShowDate(true);
  };
  // 조회기간 입력 함수
  const handleInputDate = (target: string, value: any) => {
    setSelectDate({
      ...selectDate,
      [target]: value,
    });
  };

  const onChangeJoinDate = useCallback(
    (joinDate: Date): void | undefined => {
      if (!joinDate) {
        return;
      }
      setJoinDate(joinDate);
      setShowJoinDateCalendar(false);
    },
    [joinDate]
  );

  const onFilterTextBoxChanged = useCallback(() => {
    const textBox = document.getElementById(
      "filter-text-box"
    ) as HTMLInputElement | null;
    if (!!textBox) {
      gridRef?.current?.api.setQuickFilter(textBox.value);
    }
  }, []);

  const handleRefresh = () => {
    if (startDate && endDate) {
      getCoachList.refetch();
    }
  };

  const getRowStyle = useCallback((params: any) => {
    if (params.node.rowPinned) {
      return {"font-weight": "bold"};
    }
  }, []);
  const pinnedTopRowData = useMemo(() => {
    type HeaderType = {
      [index: string]: number;
    };
    const headerData: HeaderType = {
      index: 0,
      coachVisitDays: 0,
      coachName: 0,
      coachCenterName: 0,
      coachBillingStatus: 0,
      coachPlanAmount: 0,
      coachPhoneNumber: 0,
      coachJoinDate: 0,
      coachLastAccessDate: 0,
      coachLastScheduleDate: 0,
      connectMembers: 0,
      visitMembers: 0,
      dietMembers: 0,
      scheduleMembers: 0,
      totalSchedulesCount: 0,
      totalRequestsCount: 0,
      registerRequestCount: 0,
      changeRequestCount: 0,
      sessionMemberCount: 0,
      videoViewCount: 0,
      bookmarkFolderCount: 0,
      bookmarkVideoCount: 0,
      totalMealsCount: 0,
      accessFrequency: 0,
    };
    if (rowData && rowData.length > 0) {
      for (let i = 0; i < rowData.length; i++) {
        for (const [key, value] of Object.entries(rowData[i])) {
          if (typeof value === "number" && value > 0) {
            headerData[key] += 1;
          }
        }
      }
      headerData.coachName = headerData.index;
    }
    return [headerData];
  }, [rowData]);

  const pinnedBottomRowData = useMemo(() => {
    type FooterType = {
      [index: string]: number;
    };
    const footerData: FooterType = {
      index: 0,
      coachVisitDays: 0,
      coachName: 0,
      coachCenterName: 0,
      coachBillingStatus: 0,
      coachPlanAmount: 0,
      coachPhoneNumber: 0,
      coachJoinDate: 0,
      coachLastAccessDate: 0,
      coachLastScheduleDate: 0,
      connectMembers: 0,
      visitMembers: 0,
      dietMembers: 0,
      scheduleMembers: 0,
      totalSchedulesCount: 0,
      totalRequestsCount: 0,
      registerRequestCount: 0,
      changeRequestCount: 0,
      sessionMemberCount: 0,
      videoViewCount: 0,
      bookmarkFolderCount: 0,
      bookmarkVideoCount: 0,
      totalMealsCount: 0,
      accessFrequency: 0,
    };
    if (rowData && rowData.length > 0) {
      for (let i = 0; i < rowData.length; i++) {
        for (const [key, value] of Object.entries(rowData[i])) {
          if (typeof value === "number" && value > 0) {
            footerData[key] += value;
          }
        }
      }
      footerData.coachName = footerData.index;
    }
    return [footerData];
  }, [rowData]);

  const handleClick = () => {
    setStartDate(selectDate.date[0].startDate);
    setEndDate(selectDate.date[0].endDate);
  };
  if (getCoachList.isLoading) {
    return <span>최대 15초 소요 예정..</span>;
  }

  const postSortRows = async (params: any) => {
    if (params.nodes.length > 0) {
      await Promise.all(
        params.nodes.map((node: any, index: number) => {
          node.data.index = index + 1;
        })
      );
      // for (let i = 0; i < params.nodes.length; i++) {
      //   params.nodes.data.index = i + 1;
      // }
    }
  };

  return (
    <>
      <div className="main-header" style={{width: "100%"}}>
        <div className="input_box w33" style={{marginTop: 30, marginLeft: 20}}>
          <label>
            날짜
            <input
              type="text"
              readOnly={true}
              className="input block calendar"
              style={{marginLeft: 50, width: 200}}
              onClick={handleShowDatePicker}
              placeholder={`${moment(selectDate.date[0].startDate).format(
                "YYYY.MM.DD"
              )} ~ ${moment(selectDate.date[0].endDate).format("YYYY.MM.DD")}`}
            />
            <_DateRange
              state={selectDate}
              setState={(pick: Array<{}>) => handleInputDate("date", pick)}
              toggleDatepicker={showDate}
              setToggleDatepicker={setShowDate}
            />
          </label>
          <button onClick={() => handleClick()}>검색</button>
          <label style={{marginLeft: 50}}>
            가입일
            <input
              type="text"
              readOnly={true}
              className="input block calendar"
              style={{marginLeft: 50, width: 200}}
              onClick={() => {
                setShowJoinDateCalendar(!showJoinDateCalendar);
              }}
              placeholder={moment(joinDate).format("YYYY.MM.DD")}
            />
          </label>
          <input
            type="text"
            id="filter-text-box"
            placeholder="검색어"
            style={{marginLeft: 40}}
            onInput={onFilterTextBoxChanged}
          />
          <button onClick={() => handleRefresh()} style={{marginLeft: 40}}>
            새로고침
          </button>
          <div className="customDateRangePicker">
            {showJoinDateCalendar && (
              <Calendar
                locale={ko}
                months={1}
                date={joinDate}
                onChange={onChangeJoinDate}
                dateDisplayFormat={"YYYY.MM.DD"}
              />
            )}
          </div>
        </div>
        <div
          className="input_box w33"
          style={{marginTop: 30, marginLeft: 20}}
        ></div>
      </div>

      <div className="div-template" style={{marginTop: 30}}>
        <span>검색된 결과는 총 {rowData.length}개입니다</span>
        <div
          className="ag-theme-alpine"
          style={{height: 800, width: "100%", marginTop: 30}}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            animateRows={true}
            postSortRows={postSortRows}
            pinnedTopRowData={pinnedTopRowData}
            pinnedBottomRowData={pinnedBottomRowData}
            enableCellTextSelection={true}
            ensureDomOrder={true}
            defaultColDef={{
              sortable: true,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default CoachList;
