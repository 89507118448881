import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import "../assets/css/CoachList.css";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {AgGridReact} from "ag-grid-react";

import moment from "moment";
import _DateRange from "../lib/_DateRange";
import {useQueries, useQuery, useQueryClient} from "react-query";
import {
  getCoachListByDate,
  getCoachUserFlowDataByDateV3,
} from "../api/dashBoard";
import _ from "lodash";
import CustomPinnedRowRenderer from "../components/DashBoard/customPinnedRowRenderer";
import {Calendar} from "react-date-range";
import ko from "date-fns/locale/ko";

const CoachUserFlowDataV3 = () => {
  const [selectDate, setSelectDate] = useState({
    date: [
      {
        startDate: moment().startOf("day").toDate(),
        endDate: moment().startOf("day").toDate(),
        key: "selection",
      },
    ],
  });

  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  //grid를 특정하기 위한 Hook
  const gridRef = useRef<AgGridReact<any>>(null);

  const [showDate, setShowDate] = useState(false);
  const [showJoinDateCalendar, setShowJoinDateCalendar] =
    useState<boolean>(false);
  const [joinDate, setJoinDate] = useState<Date>(
    moment("2022-08-27").startOf("d").toDate()
  );

  const onChangeJoinDate = useCallback(
    (joinDate: Date): void | undefined => {
      if (!joinDate) {
        return;
      }
      setJoinDate(joinDate);
      setShowJoinDateCalendar(false);
    },
    [joinDate]
  );

  const onFilterTextBoxChanged = useCallback(() => {
    const textBox = document.getElementById(
      "filter-text-box"
    ) as HTMLInputElement | null;
    if (!!textBox) {
      gridRef?.current?.api.setQuickFilter(textBox.value);
    }
  }, []);

  /**
   * 강사 이름 : coachName
   * 강사 접속일 수 : coachVisitDays
   * 강사 가입일 : coachJoinDate
   * 강사 최종 스케쥴 생성일 : coachLastScheduleDate
   * 접속 빈도 수 : accessFrequency
   * 연결된 회원 수 : connectMembers
   * 접속 한 회원 수 : visitMembers
   * 스케쥴한 회원 수 : scheduleMembers
   * 기간 내 스케쥴 갯수 : totalSchedules
   */
  const [rowData, setRowData] = useState([
    {
      index: 0,
      coachName: "검색 클릭!",
      coachVisitDays: 0,
      coachJoinDate: "",
      coachLastScheduleDate: "",
      accessFrequency: 0,
      connectMembers: 0,
      visitMembers: 0,
      scheduleMembers: 0,
      totalSchedulesCount: 0,
      onboardingStatus: "",
      homeTab: 0,
      scheduleTab: 0,
      managementTab: 0,
      chatTab: 0,
      homeSettingBtn: 0,
      homeRegisterScheduleBtn: 0,
      homeRequestScheduleBtn: 0,
      requestListBtn: 0,
      requestListBottomBtn: 0,
      homeMyScheduleBtn: 0,
      homeEncourageThisWeekBtn: 0,
      homeEncourageNextWeekBtn: 0,
      homeCodeShareBtn: 0,
      homeFAQBtn: 0,
      homeBannerRequestBtn: 0,
      homeBannerChatbotBtn: 0,
      homeBannerOpenScheduleBtn: 0,
      homeBannerDeadLineBtn: 0,
      homeBannerBlockBtn: 0,
      FAQConnectBtn: 0,
      FAQDisconnectBtn: 0,
      FAQRegisterSchedulesBtn: 0,
      FAQBlockBtn: 0,
      FAQDeadLineBtn: 0,
      FAQRequestPeriodBtn: 0,
      FAQOpenScheduleBtn: 0,
      FAQChatbotBtn: 0,
      scheCalEmptyBtn: 0,
      scheCalRefreshBtn: 0,
      scheCalRequestBtn: 0,
      schePlusRegisterBtn: 0,
      registerScheduleCheckBtn: 0,
      registerSchedulesCheckBtn: 0,
      registerOnetimeScheduleCheckBtn: 0,
      registerBlockCheckBtn: 0,
      changeScheduleCheckBtn: 0,
      changeOnetimeScheduleCheckBtn: 0,
      duplicatedTimePopupBtn: 0,
      deleteScheduleBtn: 0,
      deleteBlockBtn: 0,
      deleteOnetimeScheduleBtn: 0,
      deleteSchedulesBtn: 0,
      expectRegisterSchedulesBtn: 0,
      mngSortUpcomingClassBtn: 0,
      mngSortSessionDescBtn: 0,
      mngSortSessionAscBtn: 0,
      mngSortScheduleDateBtn: 0,
      mngSortMealDateBtn: 0,
      mngIndividualBtn: 0,
      mngMyScheduleBtn: 0,
      mngDietRecordBtn: 0,
      mngMemoBtn: 0,
      chatGroupSendBtn: 0,
      chatRoomRequestChatbotBtn: 0,
      requestListRowBtn: 0,
      tutorialStartBtn: 0,
      memberModeBtn: 0,
      FAQTutorialStartBtn: 0,
      scheMemoSave: 0,
      blockResetting: 0,
    },
  ]);

  const [columnDefs] = useState([
    {
      headerName: "색인",
      field: "index",
      width: 80,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          // rows that are not pinned don't use any cell renderer
          return undefined;
        }
      },
    },
    {
      headerName: "이름",
      field: "coachName",
      width: 120,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "강사 가입일",
      field: "coachJoinDate",
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "최종 스케쥴 생성일",
      field: "coachLastScheduleDate",
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "접속일",
      field: "coachVisitDays",
      width: 80,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "접속빈도",
      field: "accessFrequency",
      width: 100,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "연결된 회원",
      field: "connectMembers",
      width: 100,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "접속한 회원",
      field: "visitMembers",
      width: 100,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "스케쥴한 회원",
      field: "scheduleMembers",
      width: 120,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "기간 내 스케쥴 수",
      field: "totalSchedulesCount",
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "온보딩 상태",
      field: "onboardingStatus",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "듀토리얼",
      field: "tutorialStartBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "회원 모드",
      field: "memberModeBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "홈탭",
      field: "homeTab",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "스케쥴탭",
      field: "scheduleTab",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "회원관리탭",
      field: "managementTab",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "채팅탭",
      field: "chatTab",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "홈 설정",
      field: "homeSettingBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "수업기록 저장",
      field: "scheMemoSave",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "off재설정",
      field: "blockResetting",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "수업 없을때",
      field: "homeRegisterScheduleBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "회원 없을때",
      field: "homeRequestScheduleBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "신청변경 페이지",
      field: "requestListBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "신청변경 내역",
      field: "requestListRowBtn",
      width: 250,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "신청변경 내역 하단",
      field: "requestListBottomBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "수업 기록",
      field: "homeMyScheduleBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "수업 신청알림(금주)",
      field: "homeEncourageThisWeekBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "수업 신청알림(차주)",
      field: "homeEncourageNextWeekBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "공유하기",
      field: "homeCodeShareBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "이용방법",
      field: "homeFAQBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "회원신청 배너",
      field: "homeBannerRequestBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "챗봇시간 배너",
      field: "homeBannerChatbotBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "오픈시점 배너",
      field: "homeBannerOpenScheduleBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "데드라인 배너",
      field: "homeBannerDeadLineBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "오프 배너",
      field: "homeBannerBlockBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "연결방법 FAQ",
      field: "FAQConnectBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "회원해제 FAQ",
      field: "FAQDisconnectBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "반복등록 FAQ",
      field: "FAQRegisterSchedulesBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "시간막기 FAQ",
      field: "FAQBlockBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "데드라인 FAQ",
      field: "FAQDeadLineBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "신청기간 FAQ",
      field: "FAQRequestPeriodBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "오픈시점 FAQ",
      field: "FAQOpenScheduleBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "챗봇시간 FAQ",
      field: "FAQChatbotBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "듀토리얼 FAQ",
      field: "FAQTutorialStartBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "캘린더 빈 버튼",
      field: "scheCalEmptyBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "스케쥴 새로고침",
      field: "scheCalRefreshBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "캘린더 빨간 신청",
      field: "scheCalRequestBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "캘린더+ 스케쥴 등록",
      field: "schePlusRegisterBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "수업 등록",
      field: "registerScheduleCheckBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "반복수업 등록",
      field: "registerSchedulesCheckBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "개인일정 등록",
      field: "registerOnetimeScheduleCheckBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "시간막기 등록",
      field: "registerBlockCheckBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "수업 수정",
      field: "changeScheduleCheckBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "개인일정 수정",
      field: "changeOnetimeScheduleCheckBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "시간 겹침 팝업",
      field: "duplicatedTimePopupBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "수업 삭제",
      field: "deleteScheduleBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "시간막기 삭제",
      field: "deleteBlockBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "개인일정 삭제",
      field: "deleteOnetimeScheduleBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "반복수업 삭제",
      field: "deleteOnetimeScheduleBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "반복수업 확인",
      field: "expectRegisterSchedulesBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "다가올 수업순",
      field: "mngSortUpcomingClassBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "남은수업 적은순",
      field: "mngSortSessionDescBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "남은수업 많은순",
      field: "mngSortSessionAscBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "수업 등록순",
      field: "mngSortScheduleDateBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "식단 등록순",
      field: "mngSortMealDateBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "회원 상세정보",
      field: "mngIndividualBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "회원 수업기록",
      field: "mngMyScheduleBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "회원 식단기록",
      field: "mngDietRecordBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "회원 메모",
      field: "mngMemoBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "채팅방 단체메시지",
      field: "chatGroupSendBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "채팅방 신청 확인",
      field: "chatRoomRequestChatbotBtn",
      width: 250,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
  ]);

  // API (GET) : 스케줄 조회 api
  const getCoachList = useQuery(
    ["getCoachListByDate", [`${startDate}`, `${endDate}`, `${joinDate}`]],
    async () =>
      await getCoachUserFlowDataByDateV3(
        moment(selectDate.date[0].startDate).toISOString(),
        moment(selectDate.date[0].endDate).add(1, "day").toISOString(),
        moment(joinDate).toISOString()
      ),
    {
      onSuccess: async (res) => {
        const result = _.cloneDeep(res.data);
        console.log(res);

        for (let i = 0; i < res.data.length; i++) {
          result[i].index = i + 1;
        }

        setRowData(result);
      },
      onError: (err) => console.log(err),
      enabled: !!startDate, // disable this query from automatically running
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );

  const handleShowDatePicker = () => {
    setShowDate(true);
  };
  // 조회기간 입력 함수
  const handleInputDate = (target: string, value: any) => {
    setSelectDate({
      ...selectDate,
      [target]: value,
    });
  };

  const onBtHideName = useCallback(() => {
    gridRef?.current?.columnApi.applyColumnState({
      state: [{colId: "coachName", hide: true}],
    });
  }, []);

  //
  const onBtShowName = useCallback(() => {
    gridRef?.current?.columnApi.applyColumnState({
      state: [{colId: "coachName", hide: false}],
    });
  }, []);

  const pinnedTopRowData = useMemo(() => {
    type HeaderType = {
      [index: string]: number;
    };
    const headerData: HeaderType = {
      index: 0,
      coachName: 0,
      coachVisitDays: 0,
      coachJoinDate: 0,
      coachLastScheduleDate: 0,
      accessFrequency: 0,
      connectMembers: 0,
      visitMembers: 0,
      scheduleMembers: 0,
      totalSchedulesCount: 0,
      onboardingStatus: 0,
      homeTab: 0,
      scheduleTab: 0,
      managementTab: 0,
      chatTab: 0,
      homeSettingBtn: 0,
      homeRegisterScheduleBtn: 0,
      homeRequestScheduleBtn: 0,
      requestListBtn: 0,
      requestListBottomBtn: 0,
      homeMyScheduleBtn: 0,
      homeEncourageThisWeekBtn: 0,
      homeEncourageNextWeekBtn: 0,
      homeCodeShareBtn: 0,
      homeFAQBtn: 0,
      homeBannerRequestBtn: 0,
      homeBannerChatbotBtn: 0,
      homeBannerOpenScheduleBtn: 0,
      homeBannerDeadLineBtn: 0,
      homeBannerBlockBtn: 0,
      FAQConnectBtn: 0,
      FAQDisconnectBtn: 0,
      FAQRegisterSchedulesBtn: 0,
      FAQBlockBtn: 0,
      FAQDeadLineBtn: 0,
      FAQRequestPeriodBtn: 0,
      FAQOpenScheduleBtn: 0,
      FAQChatbotBtn: 0,
      scheCalEmptyBtn: 0,
      scheCalRefreshBtn: 0,
      scheCalRequestBtn: 0,
      schePlusRegisterBtn: 0,
      registerScheduleCheckBtn: 0,
      registerSchedulesCheckBtn: 0,
      registerOnetimeScheduleCheckBtn: 0,
      registerBlockCheckBtn: 0,
      changeScheduleCheckBtn: 0,
      changeOnetimeScheduleCheckBtn: 0,
      duplicatedTimePopupBtn: 0,
      deleteScheduleBtn: 0,
      deleteBlockBtn: 0,
      deleteOnetimeScheduleBtn: 0,
      deleteSchedulesBtn: 0,
      expectRegisterSchedulesBtn: 0,
      mngSortUpcomingClassBtn: 0,
      mngSortSessionDescBtn: 0,
      mngSortSessionAscBtn: 0,
      mngSortScheduleDateBtn: 0,
      mngSortMealDateBtn: 0,
      mngIndividualBtn: 0,
      mngMyScheduleBtn: 0,
      mngDietRecordBtn: 0,
      mngMemoBtn: 0,
      chatGroupSendBtn: 0,
      chatRoomRequestChatbotBtn: 0,
      requestListRowBtn: 0,
      tutorialStartBtn: 0,
      memberModeBtn: 0,
      FAQTutorialStartBtn: 0,
      scheMemoSave: 0,
      blockResetting: 0,
    };
    if (rowData && rowData.length > 0) {
      for (let i = 0; i < rowData.length; i++) {
        for (const [key, value] of Object.entries(rowData[i])) {
          if (typeof value === "number" && value > 0) {
            headerData[key] += 1;
          }
        }
      }
      headerData.coachName = headerData.index;
    }
    return [headerData];
  }, [rowData]);

  const pinnedBottomRowData = useMemo(() => {
    type FooterType = {
      [index: string]: number;
    };
    const footerData: FooterType = {
      index: 0,
      coachName: 0,
      coachVisitDays: 0,
      coachJoinDate: 0,
      coachLastScheduleDate: 0,
      accessFrequency: 0,
      connectMembers: 0,
      visitMembers: 0,
      scheduleMembers: 0,
      totalSchedulesCount: 0,
      onboardingStatus: 0,
      homeTab: 0,
      scheduleTab: 0,
      managementTab: 0,
      chatTab: 0,
      homeSettingBtn: 0,
      homeRegisterScheduleBtn: 0,
      homeRequestScheduleBtn: 0,
      requestListBtn: 0,
      requestListBottomBtn: 0,
      homeMyScheduleBtn: 0,
      homeEncourageThisWeekBtn: 0,
      homeEncourageNextWeekBtn: 0,
      homeCodeShareBtn: 0,
      homeFAQBtn: 0,
      homeBannerRequestBtn: 0,
      homeBannerChatbotBtn: 0,
      homeBannerOpenScheduleBtn: 0,
      homeBannerDeadLineBtn: 0,
      homeBannerBlockBtn: 0,
      FAQConnectBtn: 0,
      FAQDisconnectBtn: 0,
      FAQRegisterSchedulesBtn: 0,
      FAQBlockBtn: 0,
      FAQDeadLineBtn: 0,
      FAQRequestPeriodBtn: 0,
      FAQOpenScheduleBtn: 0,
      FAQChatbotBtn: 0,
      scheCalEmptyBtn: 0,
      scheCalRefreshBtn: 0,
      scheCalRequestBtn: 0,
      schePlusRegisterBtn: 0,
      registerScheduleCheckBtn: 0,
      registerSchedulesCheckBtn: 0,
      registerOnetimeScheduleCheckBtn: 0,
      registerBlockCheckBtn: 0,
      changeScheduleCheckBtn: 0,
      changeOnetimeScheduleCheckBtn: 0,
      duplicatedTimePopupBtn: 0,
      deleteScheduleBtn: 0,
      deleteBlockBtn: 0,
      deleteOnetimeScheduleBtn: 0,
      deleteSchedulesBtn: 0,
      expectRegisterSchedulesBtn: 0,
      mngSortUpcomingClassBtn: 0,
      mngSortSessionDescBtn: 0,
      mngSortSessionAscBtn: 0,
      mngSortScheduleDateBtn: 0,
      mngSortMealDateBtn: 0,
      mngIndividualBtn: 0,
      mngMyScheduleBtn: 0,
      mngDietRecordBtn: 0,
      mngMemoBtn: 0,
      chatGroupSendBtn: 0,
      chatRoomRequestChatbotBtn: 0,
      requestListRowBtn: 0,
      tutorialStartBtn: 0,
      memberModeBtn: 0,
      FAQTutorialStartBtn: 0,
      scheMemoSave: 0,
      blockResetting: 0,
    };
    if (rowData && rowData.length > 0) {
      for (let i = 0; i < rowData.length; i++) {
        for (const [key, value] of Object.entries(rowData[i])) {
          if (typeof value === "number" && value > 0) {
            footerData[key] += value;
          }
        }
      }
      footerData.coachName = footerData.index;
    }
    return [footerData];
  }, [rowData]);
  const handleClick = () => {
    setStartDate(selectDate.date[0].startDate);
    setEndDate(selectDate.date[0].endDate);
  };
  if (getCoachList.isLoading) {
    return <span>최대 15초 소요 예정..</span>;
  }

  const postSortRows = async (params: any) => {
    if (params.nodes.length > 0) {
      await Promise.all(
        params.nodes.map((node: any, index: number) => {
          node.data.index = index + 1;
        })
      );
      // for (let i = 0; i < params.nodes.length; i++) {
      //   params.nodes.data.index = i + 1;
      // }
    }
  };

  const handleRefresh = () => {
    if (startDate && endDate) {
      getCoachList.refetch();
    }
  };

  return (
    <>
      <div className="main-header" style={{width: "100%"}}>
        <div className="input_box w33" style={{marginTop: 30, marginLeft: 20}}>
          <label>
            날짜
            <input
              type="text"
              readOnly={true}
              className="input block calendar"
              style={{marginLeft: 50, width: 200}}
              onClick={handleShowDatePicker}
              placeholder={`${moment(selectDate.date[0].startDate).format(
                "YYYY.MM.DD"
              )} ~ ${moment(selectDate.date[0].endDate).format("YYYY.MM.DD")}`}
            />
            <_DateRange
              state={selectDate}
              setState={(pick: Array<{}>) => handleInputDate("date", pick)}
              toggleDatepicker={showDate}
              setToggleDatepicker={setShowDate}
            />
          </label>
          <button onClick={() => handleClick()}>검색</button>
          <label style={{marginLeft: 50}}>
            가입일
            <input
              type="text"
              readOnly={true}
              className="input block calendar"
              style={{marginLeft: 50, width: 200}}
              onClick={() => {
                setShowJoinDateCalendar(!showJoinDateCalendar);
              }}
              placeholder={moment(joinDate).format("YYYY.MM.DD")}
            />
          </label>
          <div className="customDateRangePicker">
            {showJoinDateCalendar && (
              <Calendar
                locale={ko}
                months={1}
                date={joinDate}
                onChange={onChangeJoinDate}
                dateDisplayFormat={"YYYY.MM.DD"}
              />
            )}
          </div>
          <input
            type="text"
            id="filter-text-box"
            placeholder="검색어"
            style={{marginLeft: 40}}
            onInput={onFilterTextBoxChanged}
          />
          <button onClick={() => handleRefresh()} style={{marginLeft: 40}}>
            새로고침
          </button>
        </div>
      </div>
      {/* <td>
        <button onClick={onBtHideName}>Hide Names</button>
        <button onClick={onBtShowName}>Show Names</button>
      </td> */}
      <div className="div-template" style={{marginTop: 20}}>
        <span>
          검색된 결과는 총 {rowData.length}개입니다
          <br />
        </span>
        <span>
          온보딩 데이터, 클릭 데이터는 9월 5일부터 현재까지 측정된 데이터입니다.
          그리고 "온보딩 상태" 이후 데이터들은 기간 필터에 영향을 받지 않는 누적
          데이터입니다.
        </span>
        <div
          className="ag-theme-alpine"
          style={{height: 800, width: "100%", marginTop: 30}}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            animateRows={true}
            postSortRows={postSortRows}
            pinnedTopRowData={pinnedTopRowData}
            pinnedBottomRowData={pinnedBottomRowData}
            defaultColDef={{
              sortable: true,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default CoachUserFlowDataV3;
