import React from "react";
import {DashBoardBox, RowContainer, VerticalLine} from "../../lib/Styled";

const DashBoardBody = (props: any) => {
  const {
    totalUserCount,
    totalCoachCount,
    totalMemberCount,
    totalRappoCount,

    dayActiveUserCount,
    dayActiveCoachCount,
    dayActiveMemberCount,

    totalScheduleCount,
    totalRequestCount,
    totalMealCount,

    scheduleCount,
    requestCount,
    mealCount,

    dailyActiveUser,
    weeklyActiveUser,
    monthlyActiveUser,
    dailyActiveCoach,
    weeklyActiveCoach,
    monthlyActiveCoach,

    dayConnectRappoCount,
    dayCoachAverageAccessFrequency,
    bookmarkFolderCount,
    bookmarkVideoCount,
    dayVideoViewCount,
    dayAverageVideoViewCount,
    totalVideoViewCount,

    dailySignUpCoachCount,
    dailySignUpMemberCount,
  } = props.mainData;
  return (
    <>
      <RowContainer>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>당일 가입 사용자 수 : </span>
          <span style={valueStyle}>
            {`${dailySignUpCoachCount + dailySignUpMemberCount}`} 명
          </span>
        </DashBoardBox>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>당일 가입 강사 수 : </span>
          <span style={valueStyle}>{`${dailySignUpCoachCount}`} 명</span>
        </DashBoardBox>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>당일 가입 회원 수 : </span>
          <span style={valueStyle}>{`${dailySignUpMemberCount}`} 명</span>
        </DashBoardBox>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>당일 라포 형성 수 : </span>
          <span style={valueStyle}>{`${dayConnectRappoCount}`} 개</span>
        </DashBoardBox>
      </RowContainer>
      <RowContainer>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>당일 접속 사용자 수 : </span>
          <span style={valueStyle}>{`${dayActiveUserCount}`} 명</span>
        </DashBoardBox>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>당일 접속 강사 수 : </span>
          <span style={valueStyle}>{`${dayActiveCoachCount}`} 명</span>
        </DashBoardBox>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>당일 접속 회원 수 : </span>
          <span style={valueStyle}>{`${dayActiveMemberCount}`} 명</span>
        </DashBoardBox>
      </RowContainer>
      <RowContainer>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>당일 생성된 일정 수 : </span>
          <span style={valueStyle}>{`${scheduleCount}`} 개</span>
        </DashBoardBox>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>당일 생성된 요청 수 : </span>
          <span style={valueStyle}>{`${requestCount}`} 개</span>
        </DashBoardBox>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>당일 생성된 식단 수 : </span>
          <span style={valueStyle}>{`${mealCount}`} 개</span>
        </DashBoardBox>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>당일 강사 평균 접속 빈도 수 : </span>
          <span style={valueStyle}>
            {`${dayCoachAverageAccessFrequency}`} 번
          </span>
        </DashBoardBox>
      </RowContainer>
      <RowContainer>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>누적 사용자 수 : </span>
          <span style={valueStyle}>{`${totalUserCount}`} 명</span>
        </DashBoardBox>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>누적 강사 수 : </span>
          <span style={valueStyle}>{`${totalCoachCount}`} 명</span>
        </DashBoardBox>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>누적 회원 수 : </span>
          <span style={valueStyle}>{`${totalMemberCount}`} 명</span>
        </DashBoardBox>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>누적 라포 형성 수 : </span>
          <span style={valueStyle}>{`${totalRappoCount}`} 명</span>
        </DashBoardBox>
      </RowContainer>
      <RowContainer>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>누적 일정 수 : </span>
          <span style={valueStyle}>{`${totalScheduleCount}`} 개</span>
        </DashBoardBox>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>누적 일정 요청 수 : </span>
          <span style={valueStyle}>{`${totalRequestCount}`} 개</span>
        </DashBoardBox>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>누적 식단 수 : </span>
          <span style={valueStyle}>{`${totalMealCount}`} 개</span>
        </DashBoardBox>
      </RowContainer>
      <RowContainer>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>전체 DAU : </span>
          <span style={valueStyle}>{`${dailyActiveUser}`} %</span>
        </DashBoardBox>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>전체 WAU : </span>
          <span style={valueStyle}>{`${weeklyActiveUser}`} %</span>
        </DashBoardBox>

        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>전체 MAU : </span>
          <span style={valueStyle}>{`${monthlyActiveUser}`} %</span>
        </DashBoardBox>
      </RowContainer>
      <RowContainer>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>강사 DAU : </span>
          <span style={valueStyle}>{`${dailyActiveCoach}`} %</span>
        </DashBoardBox>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>강사 WAU : </span>
          <span style={valueStyle}>{`${weeklyActiveCoach}`} %</span>
        </DashBoardBox>

        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>강사 MAU : </span>
          <span style={valueStyle}>{`${monthlyActiveCoach}`} %</span>
        </DashBoardBox>
      </RowContainer>
      <RowContainer>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>하루 영상 조회 수 : </span>
          <span style={valueStyle}>{`${dayVideoViewCount}`} 개</span>
        </DashBoardBox>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>하루 평균 영상 조회 수 : </span>
          <span style={valueStyle}>{`${dayAverageVideoViewCount}`} 개</span>
        </DashBoardBox>

        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>누적 영상 조회 수 : </span>
          <span style={valueStyle}>{`${totalVideoViewCount}`} 개</span>
        </DashBoardBox>
      </RowContainer>
      <RowContainer>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>전체 북마크 폴더 수 : </span>
          <span style={valueStyle}>{`${bookmarkFolderCount}`} 개</span>
        </DashBoardBox>
        <DashBoardBox style={boxStyle}>
          <span style={keyStyle}>전체 북마크 영상 수 : </span>
          <span style={valueStyle}>{`${bookmarkVideoCount}`} 개</span>
        </DashBoardBox>
      </RowContainer>
    </>
  );
};

export default DashBoardBody;

const keyStyle = {fontSize: 20, color: "#3D4244", marginLeft: 15};
const valueStyle = {
  fontSize: 20,
  color: "#3D4244",
  marginLeft: 15,
  fontWeight: "bold",
};
const boxStyle = {
  justifyContent: "space-around",
};
