import React from "react";
import {Link, NavLink} from "react-router-dom";
import "../assets/css/Sidebar.css";
import {
  FaHome,
  FaUser,
  FaCog,
  FaDatabase,
  FaLock,
  FaCalendar,
  FaCalendarDay,
  FaMoneyBill,
} from "react-icons/fa";

const SideBar = () => {
  return (
    <div className="sidebar">
      <h2>목록</h2>
      <ul>
        <li>
          <FaHome />
          <NavLink
            style={({isActive, isPending}) => {
              return {
                color: isActive ? "black" : "gray",
                textDecoration: "none",
              };
            }}
            to="/dashBoard"
          >
            메인 화면
          </NavLink>
        </li>
        <li>
          <FaDatabase />
          <NavLink
            style={({isActive, isPending}) => {
              return {
                color: isActive ? "black" : "gray",
                textDecoration: "none",
              };
            }}
            to="/coachList"
          >
            강사 리스트
          </NavLink>
        </li>
        <li>
          <FaCalendarDay />
          <NavLink
            style={({isActive, isPending}) => {
              return {
                color: isActive ? "black" : "gray",
                textDecoration: "none",
              };
            }}
            to="/coachScheduleList"
          >
            스케쥴 상세
          </NavLink>
        </li>
        <li>
          <FaUser />
          <NavLink
            style={({isActive, isPending}) => {
              return {
                color: isActive ? "black" : "gray",
                textDecoration: "none",
              };
            }}
            to="/coachUserFlowDataV3"
          >
            v3온보딩&클릭
          </NavLink>
        </li>
        <li>
          <FaUser />
          <NavLink
            style={({isActive, isPending}) => {
              return {
                color: isActive ? "black" : "gray",
                textDecoration: "none",
              };
            }}
            to="/coachUserFlowData"
          >
            예전 온보딩&클릭
          </NavLink>
        </li>
        <li>
          <FaUser />
          <NavLink
            style={({isActive, isPending}) => {
              return {
                color: isActive ? "black" : "gray",
                textDecoration: "none",
              };
            }}
            to="/rappoManagerData"
          >
            라포매니저 클릭
          </NavLink>
        </li>
        <li>
          <FaMoneyBill />
          <NavLink
            style={({isActive, isPending}) => {
              return {
                color: isActive ? "black" : "gray",
                textDecoration: "none",
              };
            }}
            to="/rappoManagerBillingData"
          >
            라포매니저 결제
          </NavLink>
        </li>

        {/* <li>
          <FaUser />
          <NavLink
            style={({isActive, isPending}) => {
              return {
                color: isActive ? "black" : "gray",
                textDecoration: "none",
              };
            }}
            to="/coach/1"
          >
            코치1
          </NavLink>
        </li>
        <li>
          <FaCog />
          <NavLink
            style={({isActive, isPending}) => {
              return {
                color: isActive ? "black" : "gray",
                textDecoration: "none",
              };
            }}
            to="/coach/2"
          >
            코치2
          </NavLink>
        </li> */}
      </ul>
    </div>
  );
};

export default SideBar;
