import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import "../assets/css/CoachList.css";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {AgGridReact} from "ag-grid-react";

import moment from "moment";
import _DateRange from "../lib/_DateRange";
import {useQueries, useQuery, useQueryClient} from "react-query";
import {getCoachListByDate, getCoachUserFlowDataByDate} from "../api/dashBoard";
import _ from "lodash";
import CustomPinnedRowRenderer from "../components/DashBoard/customPinnedRowRenderer";

const CoachUserFlowData = () => {
  const [selectDate, setSelectDate] = useState({
    date: [
      {
        startDate: moment().startOf("day").toDate(),
        endDate: moment().startOf("day").toDate(),
        key: "selection",
      },
    ],
  });

  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const onBtHideName = useCallback(() => {
    gridRef?.current?.columnApi.applyColumnState({
      state: [{colId: "coachName", hide: true}],
    });
  }, []);

  const onBtShowName = useCallback(() => {
    gridRef?.current?.columnApi.applyColumnState({
      state: [{colId: "coachName", hide: false}],
    });
  }, []);

  /**
   * 강사 이름 : coachName
   * 강사 접속일 수 : coachVisitDays
   * 강사 가입일 : coachJoinDate
   * 강사 최종 스케쥴 생성일 : coachLastScheduleDate
   * 접속 빈도 수 : accessFrequency
   * 연결된 회원 수 : connectMembers
   * 접속 한 회원 수 : visitMembers
   * 스케쥴한 회원 수 : scheduleMembers
   * 기간 내 스케쥴 갯수 : totalSchedules
   */
  const [rowData, setRowData] = useState([
    {
      index: 0,
      coachName: "검색 클릭!",
      coachVisitDays: 0,
      coachJoinDate: "",
      coachLastScheduleDate: "",
      accessFrequency: 0,
      connectMembers: 0,
      visitMembers: 0,
      scheduleMembers: 0,
      totalSchedulesCount: 0,
      onboardingStatus: "",
      homeTab: 0,
      scheduleTab: 0,
      dietTab: 0,
      chatTab: 0,
      videoTab: 0,
      onboardingShareBtn: 0,
      homeCodeBtn: 0,
      homeCodeShareBtn: 0,
      homeRegisterScheduleBtn: 0,
      homeRequestScheduleBtn: 0,
      homeEncourageBtn: 0,
      homeMyScheduleBtn: 0,
      homeGuideBtn: 0,
      scheCalEmptyBtn: 0,
      scheCalRequestBtn: 0,
      schePlusRegisterBtn: 0,
      schePlusRequestBtn: 0,
      schePlusMyScheduleBtn: 0,
      chatRoomRequestChatbotBtn: 0,
      chatGroupSendBtn: 0,
    },
  ]);

  // 스케줄 조회 api
  const getCoachList = useQuery(
    ["getCoachListByDate", [`${startDate}`, `${endDate}`]],
    async () =>
      await getCoachUserFlowDataByDate(
        moment(selectDate.date[0].startDate).toISOString(),
        moment(selectDate.date[0].endDate).add(1, "day").toISOString()
      ),
    {
      onSuccess: async (res) => {
        const result = _.cloneDeep(res.data);

        for (let i = 0; i < res.data.length; i++) {
          result[i].index = i + 1;
        }

        setRowData(result);
      },
      onError: (err) => console.log(err),
      enabled: !!startDate, // disable this query from automatically running
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );

  const [showDate, setShowDate] = useState(false);

  const handleShowDatePicker = () => {
    setShowDate(true);
  };
  // 조회기간 입력 함수
  const handleInputDate = (target: string, value: any) => {
    setSelectDate({
      ...selectDate,
      [target]: value,
    });
  };

  //grid를 특정하기 위한 Hook
  const gridRef = useRef<AgGridReact<any>>(null);

  const onFilterTextBoxChanged = useCallback(() => {
    const textBox = document.getElementById(
      "filter-text-box"
    ) as HTMLInputElement | null;
    if (!!textBox) {
      gridRef?.current?.api.setQuickFilter(textBox.value);
    }
  }, []);

  const [columnDefs] = useState([
    {
      headerName: "색인",
      field: "index",
      width: 80,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          // rows that are not pinned don't use any cell renderer
          return undefined;
        }
      },
    },
    {
      headerName: "이름",
      field: "coachName",
      width: 120,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "강사 가입일",
      field: "coachJoinDate",
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "최종 스케쥴 생성일",
      field: "coachLastScheduleDate",
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "접속일",
      field: "coachVisitDays",
      width: 80,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "접속빈도",
      field: "accessFrequency",
      width: 100,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "연결된 회원",
      field: "connectMembers",
      width: 100,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "접속한 회원",
      field: "visitMembers",
      width: 100,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "스케쥴한 회원",
      field: "scheduleMembers",
      width: 120,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "기간 내 스케쥴 수",
      field: "totalSchedulesCount",
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "온보딩 상태",
      field: "onboardingStatus",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "홈탭",
      field: "homeTab",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "스케쥴탭",
      field: "scheduleTab",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "식단탭",
      field: "dietTab",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "채팅탭",
      field: "chatTab",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "운동영상탭",
      field: "videoTab",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "온보딩 코드공유",
      field: "onboardingShareBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "홈 코드버튼",
      field: "homeCodeBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "홈 코드공유",
      field: "homeCodeShareBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "홈 스케쥴 등록",
      field: "homeRegisterScheduleBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "홈 신청 확인",
      field: "homeRequestScheduleBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "홈 미등록 회원",
      field: "homeEncourageBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "홈 스케쥴 기록",
      field: "homeMyScheduleBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "홈 가이드",
      field: "homeGuideBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "캘린더 빈 버튼",
      field: "scheCalEmptyBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "캘린더 빨간 신청",
      field: "scheCalRequestBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "캘린더+ 스케쥴 등록",
      field: "schePlusRegisterBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "캘린더+ 신청 확인",
      field: "schePlusRequestBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "캘린더+ 스케쥴 기록",
      field: "schePlusMyScheduleBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "채팅방 신청 확인",
      field: "chatRoomRequestChatbotBtn",
      width: 250,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "채팅방 단체메시지",
      field: "chatGroupSendBtn",
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
  ]);

  const pinnedTopRowData = useMemo(() => {
    type HeaderType = {
      [index: string]: number;
    };
    const headerData: HeaderType = {
      index: 0,
      coachName: 0,
      coachVisitDays: 0,
      coachJoinDate: 0,
      coachLastScheduleDate: 0,
      accessFrequency: 0,
      connectMembers: 0,
      visitMembers: 0,
      scheduleMembers: 0,
      totalSchedulesCount: 0,
      onboardingStatus: 0,
      homeTab: 0,
      scheduleTab: 0,
      dietTab: 0,
      chatTab: 0,
      videoTab: 0,
      onboardingShareBtn: 0,
      homeCodeBtn: 0,
      homeCodeShareBtn: 0,
      homeRegisterScheduleBtn: 0,
      homeRequestScheduleBtn: 0,
      homeEncourageBtn: 0,
      homeMyScheduleBtn: 0,
      homeGuideBtn: 0,
      scheCalEmptyBtn: 0,
      scheCalRequestBtn: 0,
      schePlusRegisterBtn: 0,
      schePlusRequestBtn: 0,
      schePlusMyScheduleBtn: 0,
      chatRoomRequestChatbotBtn: 0,
      chatGroupSendBtn: 0,
    };
    if (rowData && rowData.length > 0) {
      for (let i = 0; i < rowData.length; i++) {
        for (const [key, value] of Object.entries(rowData[i])) {
          if (typeof value === "number" && value > 0) {
            headerData[key] += 1;
          }
        }
      }
      headerData.coachName = headerData.index;
    }
    return [headerData];
  }, [rowData]);

  const pinnedBottomRowData = useMemo(() => {
    type FooterType = {
      [index: string]: number;
    };
    const footerData: FooterType = {
      index: 0,
      coachName: 0,
      coachVisitDays: 0,
      coachJoinDate: 0,
      coachLastScheduleDate: 0,
      accessFrequency: 0,
      connectMembers: 0,
      visitMembers: 0,
      scheduleMembers: 0,
      totalSchedulesCount: 0,
      onboardingStatus: 0,
      homeTab: 0,
      scheduleTab: 0,
      dietTab: 0,
      chatTab: 0,
      videoTab: 0,
      onboardingShareBtn: 0,
      homeCodeBtn: 0,
      homeCodeShareBtn: 0,
      homeRegisterScheduleBtn: 0,
      homeRequestScheduleBtn: 0,
      homeEncourageBtn: 0,
      homeMyScheduleBtn: 0,
      homeGuideBtn: 0,
      scheCalEmptyBtn: 0,
      scheCalRequestBtn: 0,
      schePlusRegisterBtn: 0,
      schePlusRequestBtn: 0,
      schePlusMyScheduleBtn: 0,
      chatRoomRequestChatbotBtn: 0,
      chatGroupSendBtn: 0,
    };
    if (rowData && rowData.length > 0) {
      for (let i = 0; i < rowData.length; i++) {
        for (const [key, value] of Object.entries(rowData[i])) {
          if (typeof value === "number" && value > 0) {
            footerData[key] += value;
          }
        }
      }
      footerData.coachName = footerData.index;
    }
    return [footerData];
  }, [rowData]);

  const handleClick = () => {
    setStartDate(selectDate.date[0].startDate);
    setEndDate(selectDate.date[0].endDate);
  };
  if (getCoachList.isLoading) {
    return <span>최대 15초 소요 예정..</span>;
  }

  const postSortRows = async (params: any) => {
    if (params.nodes.length > 0) {
      await Promise.all(
        params.nodes.map((node: any, index: number) => {
          node.data.index = index + 1;
        })
      );
      // for (let i = 0; i < params.nodes.length; i++) {
      //   params.nodes.data.index = i + 1;
      // }
    }
  };

  const handleRefresh = () => {
    if (startDate && endDate) {
      getCoachList.refetch();
    }
  };

  return (
    <>
      <div className="main-header" style={{width: "100%"}}>
        <div className="input_box w33" style={{marginTop: 30, marginLeft: 20}}>
          <label>
            날짜
            <input
              type="text"
              readOnly={true}
              className="input block calendar"
              style={{marginLeft: 50, width: 200}}
              onClick={handleShowDatePicker}
              placeholder={`${moment(selectDate.date[0].startDate).format(
                "YYYY.MM.DD"
              )} ~ ${moment(selectDate.date[0].endDate).format("YYYY.MM.DD")}`}
            />
            <_DateRange
              state={selectDate}
              setState={(pick: Array<{}>) => handleInputDate("date", pick)}
              toggleDatepicker={showDate}
              setToggleDatepicker={setShowDate}
            />
          </label>
          <button onClick={() => handleClick()}>검색</button>
          <input
            type="text"
            id="filter-text-box"
            placeholder="검색어"
            style={{marginLeft: 40}}
            onInput={onFilterTextBoxChanged}
          />
          <button onClick={() => handleRefresh()} style={{marginLeft: 40}}>
            새로고침
          </button>
        </div>
      </div>
      {/* <td>
        <button onClick={onBtHideName}>Hide Names</button>
        <button onClick={onBtShowName}>Show Names</button>
      </td> */}
      <div className="div-template" style={{marginTop: 75}}>
        <span>
          검색된 결과는 총 {rowData.length}개입니다
          <br />
          <br />
        </span>
        <span>
          온보딩 데이터, 클릭 데이터는 7월 4일부터 현재까지 측정된 데이터입니다!
          <br />
          온보딩 상태, 홈 탭 ~~~ 채팅데이터 까지의 데이터들은 기간 필터에 영향을
          받지 않는 누적 데이터입니다.
          <br /> <br />
          온보딩 상태 : 온보딩 과정에서 어느 상태까지 진행했는지 진행상태 기록(
          피그마상 화면명으로 저장 )
          <br /> ( ON_5 : 최초 로그인 직후 / ON_6 : 이름 입력 직후 / ON_6_4 오프
          시간 등록 직후 / ON_8 : 연결방법 알아보기 버튼 클릭 직후 / ON_10 :
          이용가이드 진입 직후 )
          <br /> ( OFFLINE : 7월 4일 이후로 미접속 / EXIST : 7월 4일 이전에
          온보딩 완료 / END : 7월 4일 이후 온보딩 완료 )
        </span>
        <div
          className="ag-theme-alpine"
          style={{height: 800, width: "100%", marginTop: 30}}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            animateRows={true}
            postSortRows={postSortRows}
            pinnedTopRowData={pinnedTopRowData}
            pinnedBottomRowData={pinnedBottomRowData}
            defaultColDef={{
              sortable: true,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default CoachUserFlowData;
