import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import "../assets/css/CoachList.css";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {AgGridReact} from "ag-grid-react";

import moment from "moment";
import _DateRange from "../lib/_DateRange";
import {useQueries, useQuery, useQueryClient} from "react-query";
import {getRappoManagerBillingDataByDate} from "../api/dashBoard";
import _ from "lodash";
import CustomPinnedRowRenderer from "../components/DashBoard/customPinnedRowRenderer";
import {Calendar} from "react-date-range";
import ko from "date-fns/locale/ko";

const RappoManagerBillingData = () => {
  const [selectDate, setSelectDate] = useState({
    date: [
      {
        startDate: moment().startOf("day").toDate(),
        endDate: moment().startOf("day").toDate(),
        key: "selection",
      },
    ],
  });

  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [rowData, setRowData] = useState([
    {
      index: 0,
      centerName: "검색 클릭!",
      adminName: "",
      lastLoginDate: "",
      loginCount: 0,
      billingStatus: "",
      billingStartDate: "",
      planAmount: "", // 숫자로 변환
      connectCoachCount: 0,
      connectCoachNames: "",
      adminId: "",
      adminCreated: "",
      adminPhoneNumber: "",
      trialDelayStart: "",
      trialExpired: "",
      centerScheduleCount: 0,
    },
  ]);

  const [showDate, setShowDate] = useState(false);
  const [showJoinDateCalendar, setShowJoinDateCalendar] =
    useState<boolean>(false);
  const [joinDate, setJoinDate] = useState<Date>(
    moment("2022-08-27").startOf("d").toDate()
  );

  const [columnDefs] = useState([
    {
      headerName: "색인",
      field: "index",
      resizable: true,
      width: 100,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          // rows that are not pinned don't use any cell renderer
          return undefined;
        }
      },
    },
    {
      headerName: "센터 이름",
      field: "centerName",
      resizable: true,
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "매니저 이름",
      field: "adminName",
      resizable: true,
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "최종 로그인 날짜",
      field: "lastLoginDate",
      resizable: true,
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "기간 내 로그인 횟수",
      field: "loginCount",
      resizable: true,
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "결제 상태",
      field: "billingStatus",
      resizable: true,
      width: 200,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "결제 시작일",
      field: "billingStartDate",
      resizable: true,
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "현재 플랜 금액",
      field: "planAmount",
      resizable: true,
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "연결 강사 수",
      field: "connectCoachCount",
      resizable: true,
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "연결 강사 이름",
      field: "connectCoachNames",
      resizable: true,
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "매니저 아이디",
      field: "adminId",
      resizable: true,
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "매니저 가입일",
      field: "adminCreated",
      resizable: true,
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "매니저 번호",
      field: "adminPhoneNumber",
      resizable: true,
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "무료체험 만료(유예시작)",
      field: "trialDelayStart",
      resizable: true,
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "무료체험 종료",
      field: "trialExpired",
      resizable: true,
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "기간 내 센터 스케쥴 생성 수",
      field: "centerScheduleCount",
      resizable: true,
      width: 150,
      cellRendererSelector: (params: any) => {
        if (params.node.rowPinned) {
          return {
            component: CustomPinnedRowRenderer,
            params: {
              style: {color: "blue"},
            },
          };
        } else {
          return undefined;
        }
      },
    },
  ]);

  //grid를 특정하기 위한 Hook
  const gridRef = useRef<AgGridReact<any>>(null);

  // 스케줄 조회 api
  const getRappoManagerBillingData = useQuery(
    [
      "getRappoManagerBillingDataByDate",
      [`${startDate}`, `${endDate}`, `${joinDate}`],
    ],
    async () =>
      await getRappoManagerBillingDataByDate(
        moment(selectDate.date[0].startDate).toISOString(),
        moment(selectDate.date[0].endDate).add(1, "day").toISOString(),
        moment(joinDate).toISOString()
      ),
    {
      onSuccess: async (res) => {
        const result = _.cloneDeep(res.data);

        for (let i = 0; i < res.data.length; i++) {
          result[i].index = i + 1;
        }

        setRowData(result);
      },
      onError: (err) => console.log(err),
      enabled: !!startDate, // disable this query from automatically running
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );

  const handleShowDatePicker = () => {
    setShowDate(true);
  };
  // 조회기간 입력 함수
  const handleInputDate = (target: string, value: any) => {
    setSelectDate({
      ...selectDate,
      [target]: value,
    });
  };

  const onChangeJoinDate = useCallback(
    (joinDate: Date): void | undefined => {
      if (!joinDate) {
        return;
      }
      setJoinDate(joinDate);
      setShowJoinDateCalendar(false);
    },
    [joinDate]
  );

  const onFilterTextBoxChanged = useCallback(() => {
    const textBox = document.getElementById(
      "filter-text-box"
    ) as HTMLInputElement | null;
    if (!!textBox) {
      gridRef?.current?.api.setQuickFilter(textBox.value);
    }
  }, []);

  const handleRefresh = () => {
    if (startDate && endDate) {
      getRappoManagerBillingData.refetch();
    }
  };

  const getRowStyle = useCallback((params: any) => {
    if (params.node.rowPinned) {
      return {"font-weight": "bold"};
    }
  }, []);

  const [indexCount, setIndexCount] = useState<any>(0);

  const [adminTypeState, setAdminTypeState] = useState<any>("live");
  let adminType = "live";

  const externalFilterChanged = useCallback((newValue: any) => {
    setAdminTypeState(newValue);
    adminType = newValue;
    gridRef?.current?.api.onFilterChanged();
  }, []);

  const isExternalFilterPresent = useCallback(() => {
    // if adminType is not live, then we are filtering
    return adminType !== "everyone";
  }, []);

  const doesExternalFilterPass = useCallback(
    (node: any) => {
      if (node.data) {
        return getAdminTypeFlag(adminType, node.data.adminName);
      }
      return true;
    },
    [adminType]
  );

  const getAdminTypeFlag = (adminType: string, adminName: string) => {
    switch (adminType) {
      case "everyone":
        return true;
      case "live":
        return adminName.indexOf("[T]") < 0;
      case "test":
        return adminName.indexOf("[T]") > 0;
      default:
        return true;
    }
  };

  const pinnedTopRowData = useMemo(() => {
    type HeaderType = {
      [index: string]: number;
    };
    const headerData: HeaderType = {
      index: 0,
      centerName: 0,
      adminName: 0,
      lastLoginDate: 0,
      loginCount: 0,
      billingStatus: 0,
      billingStartDate: 0,
      planAmount: 0,
      connectCoachCount: 0,
      connectCoachNames: 0,
      adminId: 0,
      adminCreated: 0,
      adminPhoneNumber: 0,
      trialDelayStart: 0,
      trialExpired: 0,
      centerScheduleCount: 0,
    };
    if (rowData && rowData.length > 0) {
      for (let i = 0; i < rowData.length; i++) {
        if (getAdminTypeFlag(adminTypeState, rowData[i].adminName)) {
          for (const [key, value] of Object.entries(rowData[i])) {
            if (typeof value === "number" && value > 0) {
              headerData[key] += 1;
            }
          }
        }
      }
      headerData.coachName = headerData.index;
      setIndexCount(headerData.index);
    }
    return [headerData];
  }, [rowData, adminTypeState]);

  const pinnedBottomRowData = useMemo(() => {
    type FooterType = {
      [index: string]: number;
    };
    const footerData: FooterType = {
      index: 0,
      centerName: 0,
      adminName: 0,
      lastLoginDate: 0,
      loginCount: 0,
      billingStatus: 0,
      billingStartDate: 0,
      planAmount: 0,
      connectCoachCount: 0,
      connectCoachNames: 0,
      adminId: 0,
      adminCreated: 0,
      adminPhoneNumber: 0,
      trialDelayStart: 0,
      trialExpired: 0,
      centerScheduleCount: 0,
    };
    if (rowData && rowData.length > 0) {
      for (let i = 0; i < rowData.length; i++) {
        if (getAdminTypeFlag(adminTypeState, rowData[i].adminName)) {
          for (const [key, value] of Object.entries(rowData[i])) {
            if (typeof value === "number" && value > 0) {
              footerData[key] += value;
            }
          }
        }
      }
      footerData.coachName = footerData.index;
    }
    return [footerData];
  }, [rowData, adminTypeState]);

  const handleClick = () => {
    setStartDate(selectDate.date[0].startDate);
    setEndDate(selectDate.date[0].endDate);
  };
  if (getRappoManagerBillingData.isLoading) {
    return <span>최대 15초 소요 예정..</span>;
  }

  const postSortRows = async (params: any) => {
    if (params.nodes.length > 0) {
      await Promise.all(
        params.nodes.map((node: any, index: number) => {
          node.data.index = index + 1;
        })
      );
      // for (let i = 0; i < params.nodes.length; i++) {
      //   params.nodes.data.index = i + 1;
      // }
    }
  };

  return (
    <>
      <div className="main-header" style={{width: "100%"}}>
        <div className="input_box w33" style={{marginTop: 30, marginLeft: 20}}>
          <label>
            날짜
            <input
              type="text"
              readOnly={true}
              className="input block calendar"
              style={{marginLeft: 50, width: 200}}
              onClick={handleShowDatePicker}
              placeholder={`${moment(selectDate.date[0].startDate).format(
                "YYYY.MM.DD"
              )} ~ ${moment(selectDate.date[0].endDate).format("YYYY.MM.DD")}`}
            />
            <_DateRange
              state={selectDate}
              setState={(pick: Array<{}>) => handleInputDate("date", pick)}
              toggleDatepicker={showDate}
              setToggleDatepicker={setShowDate}
            />
          </label>
          <button onClick={() => handleClick()}>검색</button>
          <label style={{marginLeft: 50}}>
            가입일
            <input
              type="text"
              readOnly={true}
              className="input block calendar"
              style={{marginLeft: 50, width: 200}}
              onClick={() => {
                setShowJoinDateCalendar(!showJoinDateCalendar);
              }}
              placeholder={moment(joinDate).format("YYYY.MM.DD")}
            />
          </label>
          <input
            type="text"
            id="filter-text-box"
            placeholder="검색어"
            style={{marginLeft: 40}}
            onInput={onFilterTextBoxChanged}
          />
          <button onClick={() => handleRefresh()} style={{marginLeft: 40}}>
            새로고침
          </button>
          <div className="customDateRangePicker">
            {showJoinDateCalendar && (
              <Calendar
                locale={ko}
                months={1}
                date={joinDate}
                onChange={onChangeJoinDate}
                dateDisplayFormat={"YYYY.MM.DD"}
              />
            )}
          </div>
        </div>
        <div className="test-header" style={{marginTop: 20}}>
          <label style={{marginLeft: 20}}>
            <input
              type="radio"
              name="filter"
              id="everyone"
              checked={adminTypeState == "everyone"}
              onChange={() => externalFilterChanged("everyone")}
            />
            전체
          </label>
          <label style={{marginLeft: 20}}>
            <input
              type="radio"
              name="filter"
              id="live"
              checked={adminTypeState == "live"}
              onChange={() => externalFilterChanged("live")}
            />
            본 계정
          </label>
          <label style={{marginLeft: 20}}>
            <input
              type="radio"
              name="filter"
              id="test"
              checked={adminTypeState == "test"}
              onChange={() => externalFilterChanged("test")}
            />
            테스트 계정
          </label>
        </div>
        <div
          className="input_box w33"
          style={{marginTop: 10, marginLeft: 20}}
        ></div>
      </div>

      <div className="div-template" style={{marginTop: 20}}>
        <span>검색된 결과는 총 {indexCount}개입니다</span>
        <div
          className="ag-theme-alpine"
          style={{height: 800, width: "100%", marginTop: 30}}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            animateRows={true}
            postSortRows={postSortRows}
            pinnedTopRowData={pinnedTopRowData}
            pinnedBottomRowData={pinnedBottomRowData}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
            enableCellTextSelection={true}
            ensureDomOrder={true}
            defaultColDef={{
              sortable: true,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default RappoManagerBillingData;
