import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { DateRangePicker } from "react-date-range";

import moment from "moment";

import { ko } from "date-fns/locale";
import "./customePicker.css";

const _DateRange = (props: any) => {
  const { toggleDatepicker, setToggleDatepicker, state, setState } = props;

  const ref = useRef<any>(null);

  const [selectedCnt, setCnt] = useState(0);

  useEffect(() => {
    if (selectedCnt === 2) {
      setCnt(0);
      setTimeout(() => setToggleDatepicker(false), 300);
    }

    return () => {};
  }, [selectedCnt, setToggleDatepicker, state]);

  const outsideClick = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setToggleDatepicker(false);
    }
  };

  useEffect(() => {
    if (toggleDatepicker) {
      document.addEventListener("mousedown", outsideClick);
    }

    return () => {
      setCnt(0);
      document.removeEventListener("mousedown", outsideClick);
    };
  }, [toggleDatepicker]);

  const isSelectedFn = (startDate: any, endDate: any) => {
    let selected = false;
    if (
      startDate.format("YYYYMMDD") ===
        moment(state.date[0].startDate).format("YYYYMMDD") &&
      endDate.format("YYYYMMDD") ===
        moment(state.date[0].endDate).format("YYYYMMDD")
    ) {
      selected = true;
    }
    return selected;
  };

  const ordinalNumber = useCallback((dirtyNumber: any, dirtyOptions: any) => {
    var options = dirtyOptions || {};
    var unit = String(options.unit);

    var number = Number(dirtyNumber);

    if (unit === "year") {
      return number + "년";
    } else if (unit === "date") {
      return number + "일";
    }
  }, []);

  const customLocale = useMemo(() => {
    let _ko: any = ko;
    _ko = {
      ..._ko,
      localize: {
        ..._ko.localize,
        ordinalNumber,
      },
    };
    return _ko;
  }, []);

  const closePicker = useCallback(() => {
    setTimeout(() => setToggleDatepicker(false), 300);
  }, [setToggleDatepicker]);

  useEffect(() => {
    if (toggleDatepicker)
      document.querySelectorAll(".rdrStaticRange").forEach((button: any) => {
        if (button.querySelector("span")) {
          button
            .querySelector("span")
            .addEventListener("click", closePicker, false);
        }
      });

    return () => {};
  }, [closePicker, toggleDatepicker]);

  return (
    toggleDatepicker && (
      <div ref={ref} className="customDateRangePicker">
        <DateRangePicker
          locale={customLocale}
          onChange={(item) => {
            setState([item.selection]);
            setCnt(selectedCnt + 1);
          }}
          ranges={state.date}
          months={2}
          direction="horizontal"
          className="customRangePicker"
          showDateDisplay={true}
          dateDisplayFormat="Yo MMM do"
          monthDisplayFormat="Yo MMM"
          renderStaticRangeLabel={(range) => <div>{range.label}</div>}
          staticRanges={[
            {
              label: "지난 주",
              hasCustomRendering: true,
              isSelected: (rage) =>
                isSelectedFn(
                  moment().subtract("1", "week").startOf("week"),
                  moment().subtract("1", "week").endOf("week")
                ),
              range: () => ({
                startDate: moment()
                  .subtract("1", "week")
                  .startOf("week")
                  .toDate(),
                endDate: moment().subtract("1", "week").endOf("week").toDate(),
              }),
            },
            {
              label: `지난 달`,
              hasCustomRendering: true,
              isSelected: (rage) =>
                isSelectedFn(
                  moment().subtract("1", "month").startOf("month"),
                  moment().subtract("1", "month").endOf("month")
                ),
              range: () => ({
                startDate: moment()
                  .subtract("1", "month")
                  .startOf("month")
                  .toDate(),
                endDate: moment()
                  .subtract("1", "month")
                  .endOf("month")
                  .toDate(),
              }),
            },
            {
              label: `이번 달`,
              hasCustomRendering: true,
              isSelected: (rage) =>
                isSelectedFn(moment().startOf("month"), moment()),
              range: () => ({
                startDate: moment().startOf("month").toDate(),
                endDate: moment().toDate(),
              }),
            },
            {
              label: "최근 7일",
              hasCustomRendering: true,
              isSelected: (rage) =>
                isSelectedFn(moment().subtract("1", "week"), moment()),
              range: () => ({
                startDate: moment().subtract("1", "week").toDate(),
                endDate: moment().toDate(),
              }),
            },
            {
              label: "최근 30일",
              hasCustomRendering: true,
              isSelected: (rage) =>
                isSelectedFn(moment().subtract("1", "month"), moment()),
              range: () => ({
                startDate: moment().subtract("1", "month").toDate(),
                endDate: moment().toDate(),
              }),
            },
            {
              label: "최근 1년",
              hasCustomRendering: true,
              isSelected: (rage) =>
                isSelectedFn(moment().subtract("1", "year"), moment()),
              range: () => ({
                startDate: moment().subtract("1", "year").toDate(),
                endDate: moment().toDate(),
              }),
            },
          ]}
        />
      </div>
    )
  );
};

export default _DateRange;
