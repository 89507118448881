import _ from "lodash";
import moment from "moment";
import React, {useCallback, useState} from "react";
import {useQuery} from "react-query";
import {getMainDataByDate} from "../api/dashBoard";
import Chart from "../components/DashBoard/Chart";
import DashBoardBody from "../components/DashBoard/DashBoardBody";
import DashBoardHeader from "../components/DashBoard/DashBoardHeader";

const DashBoard = () => {
  const today = moment().startOf("d").toDate();
  const [date, setDate] = useState<Date>(today);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [mainData, setMainData] = useState([
    {
      totalUserCount: 0,
      totalCoachCount: 0,
      totalMemberCount: 0,
      totalRappoCount: 0,

      todayActiveUserCount: 0,
      todayActiveCoachCount: 0,
      todayActiveMemberCount: 0,

      totalScheduleCount: 0,
      totalRequestCount: 0,
      totalMealCount: 0,

      scheduleCount: 0,
      requestCount: 0,
      mealCount: 0,

      dailyActiveUser: 0,
      weeklyActiveUser: 0,
      monthlyActiveUser: 0,
      weekCoachCounts: [0, 0, 0, 0, 0, 0, 0],
      weekMemberCounts: [0, 0, 0, 0, 0, 0, 0],

      dailyActiveCoach: 0,
      weeklyActiveCoach: 0,
      monthlyActiveCoach: 0,

      bookmarkFolderCount: 0,
      bookmarkVideoCount: 0,

      dayVideoViewCount: 0,
      dayAverageVideoViewCount: 0,
      totalVideoViewCount: 0,
    },
  ]);

  const onChangeDate = useCallback(
    (date: Date): void | undefined => {
      if (!date) {
        return;
      }
      setDate(date);
      setShowCalendar(false);
    },
    [date]
  );

  // 메인화면 데이터 조회 api
  const getMainData = useQuery(
    ["getCoachListByDate", [`${date}`]],
    async () => await getMainDataByDate(moment(date).toISOString()),
    {
      onSuccess: async (res) => {
        const result = _.cloneDeep(res.data);
        setMainData(result);
      },
      onError: (err) => console.log(err),
      enabled: !!date, // disable this query from automatically running
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );

  return (
    <div>
      <DashBoardHeader
        showCalendar={showCalendar}
        setShowCalendar={setShowCalendar}
        date={date}
        onChangeDate={onChangeDate}
      />
      <Chart mainData={mainData} date={date} />
      <DashBoardBody mainData={mainData} />
    </div>
  );
};

export default DashBoard;
