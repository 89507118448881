import styled from "styled-components";
import React from "react";

export const ColContainer = styled.div``;
export const RowContainer = styled.div`
  flex-direction: row;
  display: flex;
`;

export const DashBoardBox = styled.div`
  width: 260px;
  height: 100px;
  display: flex;
  background: #c9dbff66;
  margin: 20px;
  border-radius: 20px;
  align-items: center;
`;

export const VerticalLine = styled.div`
  border-left: 2px solid black;
  height: 80%; /* 세로 줄의 높이 */
  margin-left: 20px;
  margin-right: 20px;
`;
