import axios, {AxiosResponse} from "axios";
import moment from "moment";

export /**
 * @method post
 * @brief 코치 조회하는 api
 */
const loginByPassword = async (password: any): Promise<any> => {
  let response = {} as AxiosResponse<any, any>;
  try {
    response = await axios({
      method: "post",
      url: `/dashboard/login`,
      // headers: {authorization: accessToken},
      data: {password: password},
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error: any) {
    // Error 😨
    switch (error.response.status) {
      case 401:
        return {status: error.response.status};
      case 403:
        return {status: error.response.status};
      case 404:
        return {status: error.response.status};
      case 500:
        return {status: error.response.status};
    }
  }
};

export /**
 * @method get
 * @brief 코치 조회하는 api
 */
const getCoachListByDate = async (
  startTime: any,
  endTime: any,
  joinDate?: any
): Promise<any> => {
  let response = {} as AxiosResponse<any, any>;
  try {
    response = await axios({
      method: "get",
      url: `/v2/dashboard/feature-data?startTime=${startTime}&endTime=${endTime}&joinDate=${joinDate}`,
      // headers: {authorization: accessToken},
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error: any) {
    // Error 😨
    switch (error.response.status) {
      case 401:
        return {status: error.response.status};
      case 403:
        return {status: error.response.status};
      case 404:
        return {status: error.response.status};
      case 500:
        return {status: error.response.status};
    }
  }
};

export /**
 * @method get
 * @brief 메인화면 데이터 조회하는 api
 */
const getMainDataByDate = async (date: any): Promise<any> => {
  let response = {} as AxiosResponse<any, any>;
  const [startTime, endTime] = [
    moment(date).startOf("d").toISOString(),
    moment(date).startOf("d").add(1, "d").toISOString(),
  ];
  try {
    response = await axios({
      method: "get",
      url: `/dashboard/main?startTime=${startTime}&endTime=${endTime}`,
      // headers: {authorization: accessToken},
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error: any) {
    // Error 😨
    switch (error.response.status) {
      case 401:
        return {status: error.response.status};
      case 403:
        return {status: error.response.status};
      case 404:
        return {status: error.response.status};
      case 500:
        return {status: error.response.status};
    }
  }
};

export /**
 * @method get
 * @brief 코치 조회하는 api
 */
const getCoachScheduleListByDate = async (
  startTime: any,
  endTime: any
): Promise<any> => {
  let response = {} as AxiosResponse<any, any>;
  try {
    response = await axios({
      method: "get",
      url: `/dashboard/schedule-data?startTime=${startTime}&endTime=${endTime}`,
      // headers: {authorization: accessToken},
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error: any) {
    // Error 😨
    switch (error.response.status) {
      case 401:
        return {status: error.response.status};
      case 403:
        return {status: error.response.status};
      case 404:
        return {status: error.response.status};
      case 500:
        return {status: error.response.status};
    }
  }
};

export /**
 * @method get
 * @brief 코치 조회하는 api
 */
const getCoachUserFlowDataByDate = async (
  startTime: any,
  endTime: any
): Promise<any> => {
  let response = {} as AxiosResponse<any, any>;
  try {
    response = await axios({
      method: "get",
      url: `/dashboard/userflow-data?startTime=${startTime}&endTime=${endTime}`,
      // headers: {authorization: accessToken},
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error: any) {
    // Error 😨
    switch (error.response.status) {
      case 401:
        return {status: error.response.status};
      case 403:
        return {status: error.response.status};
      case 404:
        return {status: error.response.status};
      case 500:
        return {status: error.response.status};
    }
  }
};

export /**
 * @method get
 * @brief 코치 조회하는 api
 */
const getCoachUserFlowDataByDateV3 = async (
  startTime: any,
  endTime: any,
  joinDate?: any
): Promise<any> => {
  let response = {} as AxiosResponse<any, any>;
  try {
    response = await axios({
      method: "get",
      url: `/dashboard/userflow-data-v2?startTime=${startTime}&endTime=${endTime}&joinDate=${joinDate}`,
      // headers: {authorization: accessToken},
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error: any) {
    // Error 😨
    switch (error.response.status) {
      case 401:
        return {status: error.response.status};
      case 403:
        return {status: error.response.status};
      case 404:
        return {status: error.response.status};
      case 500:
        return {status: error.response.status};
    }
  }
};

export /**
 * @method get
 * @brief 라포매니저 데이터 조회하는 api
 */
const getRappoManagerDataByDate = async (
  startTime: any,
  endTime: any,
  joinDate?: any
): Promise<any> => {
  let response = {} as AxiosResponse<any, any>;
  try {
    response = await axios({
      method: "get",
      url: `/dashboard//rappomanager-data?startTime=${startTime}&endTime=${endTime}&joinDate=${joinDate}`,
      // headers: {authorization: accessToken},
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error: any) {
    // Error 😨
    switch (error.response.status) {
      case 401:
        return {status: error.response.status};
      case 403:
        return {status: error.response.status};
      case 404:
        return {status: error.response.status};
      case 500:
        return {status: error.response.status};
    }
  }
};

export /**
 * @method get
 * @brief 라포매니저 데이터 조회하는 api
 */
const getRappoManagerBillingDataByDate = async (
  startTime: any,
  endTime: any,
  joinDate?: any
): Promise<any> => {
  let response = {} as AxiosResponse<any, any>;
  try {
    response = await axios({
      method: "get",
      url: `/dashboard//rappomanager-billing-data?startTime=${startTime}&endTime=${endTime}&joinDate=${joinDate}`,
      // headers: {authorization: accessToken},
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error: any) {
    // Error 😨
    switch (error.response.status) {
      case 401:
        return {status: error.response.status};
      case 403:
        return {status: error.response.status};
      case 404:
        return {status: error.response.status};
      case 500:
        return {status: error.response.status};
    }
  }
};
